<template>
    <v-container fluid fill-height>
        <v-layout row wrap fill-height>
            <!-- Toggle editor portion -->
            <v-btn
                color="purple"
                dark
                fixed
                medium
                fab
                bottom
                right
                @click="editorShown = !editorShown"
            ><v-icon>{{ editorShown ? 'edit_off' : 'edit' }}</v-icon></v-btn>

            <!-- Editor -->
            <v-flex :class="{ 'd-none': !editorShown }" xs12 sm6>
                <v-card flat tile color="white" elevation="8">
                    <v-toolbar dense flat color="purple">
                        <v-toolbar-items class="hidden-sm-and-down">
                            <v-menu icon bottom right>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon flat style="color:white;" class="ma-0" v-on="on"><v-icon>title</v-icon></v-btn>
                                </template>
                                <v-list>
                                    <v-list-tile @click="optionH1()">
                                        <v-list-tile-content>H1</v-list-tile-content>
                                    </v-list-tile>
                                    <v-list-tile @click="optionH2()">
                                        <v-list-tile-content>H2</v-list-tile-content>
                                    </v-list-tile>
                                    <v-list-tile @click="optionH3()">
                                        <v-list-tile-content>H3</v-list-tile-content>
                                    </v-list-tile>
                                </v-list>
                            </v-menu>
                            <v-btn icon flat class="ma-0" style="color:white;" @click="insertBold()"><v-icon>format_bold</v-icon></v-btn>
                            <v-btn icon flat class="ma-0" style="color:white;" @click="insertItalic()"><v-icon>format_italic</v-icon></v-btn>

                        </v-toolbar-items>
                    </v-toolbar>
                    <v-textarea
                        v-model="markdownText"
                        style="max-height: calc(100vh - 64px);"
                        box
                        height="500"
                        full-width
                        no-resize
                        hide-details
                    ></v-textarea>
                </v-card>
            </v-flex>
            <!-- Viewer -->
            <v-flex :class="{ 'xs12': !editorShown, 'sm6': editorShown }">
                <v-card flat tile color="transparent" elevation="8">
                    <div
                        v-html="markdownHTML"
                        class="markdown-wrapper"
                        style="min-height:550px; max-height: calc(100vh - 64px); overflow-y: auto; background-color: #282828; color:white; padding:8px;"
                    ></div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
    data: () => ({
        markdownText: '# _Écrire en Markdown génial !_\n' +
            '---\n\n' +
            '<br><p>Voici un exemple de documentation MD</p>\n' +
            '\n' +
            '<kbd>npm install <code>&lt;project name&gt;</code></kbd>\n\n' +
            '<var>v = u * e</var>\n'+
            '\n' +
            '| Command | Description |\n' +
            '| --- | --- |\n' +
            '| `git status` | List all *new or modified* files |\n' +
            '| `git diff` | Show file differences that **haven\'t been** staged |',
        md: new MarkdownIt({
            html: true,
            linkify: true,
            typographer: true,
            tables: true,
        }),
        editorShown: false,
    }),
    computed: {
        markdownHTML() {
            return this.md.render(this.markdownText)
        }
    },
    methods:{
        insertBold() {
            let selection = window.getSelection().toString();
            if (selection) {
                this.markdownText = this.markdownText.replace(selection, `**${selection}**`);
            }
        },
        insertItalic() {
            let selection = window.getSelection().toString();
            if (selection) {
                this.markdownText = this.markdownText.replace(selection, `_${selection}_`);
            }
        },
        optionH1() {
            let selection = window.getSelection().toString();
            if (selection) {
                this.markdownText = this.markdownText.replace(selection, `# ${selection}`);
            }
        },
        optionH2() {
            let selection = window.getSelection().toString();
            if (selection) {
                this.markdownText = this.markdownText.replace(selection, `## ${selection}`);
            }
        },
        optionH3() {
            let selection = window.getSelection().toString();
            if (selection) {
                this.markdownText = this.markdownText.replace(selection, `### ${selection}`);
            }
        },
    }
}
</script>

<style>
.markdown-wrapper table {
    width: 100% !important;
    border-collapse: collapse !important;
    margin: 20px 0 !important;
    background-color: #444 !important;
    color: white !important;
}
.markdown-wrapper th,
.markdown-wrapper td {
    border: 1px solid white;
    padding: 10px;
    text-align: left;
}
.markdown-wrapper th {
    background-color: #333;
    color: white;
    font-weight: bold;
}
</style>

<template>
    <div class="container-wrapper">
        <div class="wrapper-box-left">
            <v-card
                class="mx-auto"
                elevation="0"
                style="background-color:#9a9a9a;"
            >
                <v-sheet class="pa-3 purple">
                    <v-text-field
                        v-model="search"
                        label="Rechercher documentation"
                        dark
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                    <v-checkbox
                        v-model="caseSensitive"
                        dark
                        hide-details
                        label="Recherche sensible à la case"
                    ></v-checkbox>
                </v-sheet>
                <v-card-text style="height: 470px;overflow-x: auto;overflow-y: auto;">
                    <v-treeview
                        :items="items"
                        :open="['Développement TI Stageline']"
                        :search="search"
                        :filter="filter"
                        :open.sync="open"
                        item-key="name"
                        open-on-click
                    >
                        <template v-slot:prepend="{ item, open }">
                            <v-icon v-if="item.children.length > 0">
                                {{ open ? 'folder_open' : 'folder' }}
                            </v-icon>
                            <v-icon v-else>insert_drive_file</v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            <span v-if="item.children.length === 0" @click="getDocument(item)">
                              {{ item.name }}
                            </span>
                            <span v-else>
                              {{ item.name }}
                            </span>
                        </template>
                    </v-treeview>
                </v-card-text>
            </v-card>
        </div>
        <div class="wrapper-box-right">
            <MarkdownIt></MarkdownIt>
        </div>
    </div>
</template>

<script>
import MarkdownIt from "@/components/dev/controls/MarkdownIt.vue";

export default {
    name: "DevDoc",
    components: {
        MarkdownIt
    },
    data: function() {
        return {
            loading: null,
            items: [
                {
                    id: 1,
                    name: 'Développement TI Stageline',
                    children: [
                        {
                            id: 2,
                            name: 'Applications',
                            children: [
                                {
                                    id: 201,
                                    name: 'GNC',
                                    children:[
                                        {
                                            id: 2001,
                                            name: 'Overview',
                                            children:[]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: 3,
                            name: 'Ressources',
                            children: [
                                {
                                    id: 301,
                                    name: 'Logique applicative SmartPower',
                                    children:[]
                                },
                            ]
                        },
                        {
                            id: 4,
                            name: 'Liens',
                            children: [
                                {
                                    id: 401,
                                    name: 'Références VueJs',
                                    children:[]
                                },
                                {
                                    id: 402,
                                    name: 'Références Php',
                                    children:[]
                                },
                                {
                                    id: 403,
                                    name: 'Références Laravel',
                                    children:[]
                                }
                            ]
                        }
                    ]
                }
            ],
            open: [1, 2],
            search: null,
            caseSensitive: false,
            selectedDocName:null
        }
    },
    methods:{
        getDocument(item){
            this.selectedDocName = item.name;
        }
    },
    computed: {
        filter () {
            return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
        }
    }
}
</script>

<style scoped>
.container-wrapper{
    display:inline-flex;
    width:100%;
    overflow-x:auto;
    overflow-y: hidden;
}
.wrapper-box-left{
    width:25%;
    height: 600px;
    margin:0 auto;
    border:2px outset silver;
    border-radius: 8px;
    background-color:#9a9a9a;
    box-shadow: 1px 1px 5px rgba(1, 1, 1, 0.3);
}
.wrapper-box-right{
    width:74%;
    height: 600px;
    margin:0 auto;
    border:2px outset silver;
    border-radius: 8px;
    background-color: #c9c9c9;
    box-shadow: 1px 1px 5px rgba(1, 1, 1, 0.3);
    overflow-y: auto;
}
</style>

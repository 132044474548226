import '@babel/polyfill';
import Vue from 'vue';
import './plugins/vuetify';
import './filters';
import App from './App.vue';
import router from './router';
import store from './store/store';
import VueGoogleCharts from 'vue-google-charts';
import VuetifyConfirm from 'vuetify-confirm';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import './assets/main.css';

Vue.use(VuetifyConfirm);
Vue.config.productionTip = false;
Vue.use(Toast, {
    position: "top-right", // Position des notifications
    timeout: 5000, // Durée en ms avant disparition automatique
    closeOnClick: true, // Ferme la notification en cliquant dessus
    pauseOnHover: true, // Pause le timer lorsqu'on survole
    draggable: true, // Permet de glisser la notification pour la fermer
    icon: true, // Affiche une icône
    hideProgressBar: false, // Affiche la barre de progression
});
Vue.prototype.$toast = Vue.$toast;

new Vue({
    router,
    store,
    VueGoogleCharts,
    render: h => h(App)
}).$mount('#app');

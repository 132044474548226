<template>
    <div class="container-wrapper">
        <!-- Box pour query -->
        <div class="sandBox-box-query mb-3">
            <h2 class="title-section-wrapper">
                <span>Requête</span>
            </h2>

            <!-- Input Query -->
            <div class="pa-2 mt-1">
                <v-select
                    v-model="selectedActionType"
                    :items="actionsTypes"
                    label="Type action"
                    solo
                    style="width: 100%;"
                    color="purple"
                    class="mr-3"
                    :disabled="loading"
                >
                    <template v-slot:selection="{ item }">
                        <h4>{{ item.text }}</h4>
                    </template>
                </v-select>

                <v-text-field
                    v-model="selectedUri"
                    label="URI"
                    single-line
                    solo
                    style="width: 100%; font-weight: bolder;font-size: 18px;"
                    color="purple"
                    class="mr-3 custom-input-background"
                    :disabled="loading"
                ></v-text-field>

                <!-- Actions btn -->
                <div style="width:100%; display: inline-flex;">
                    <v-btn
                        round
                        style="background-color: black; color:white; margin:0 auto;"
                        @click="fetchTypeAction(selectedActionType, selectedUri)"
                        :disabled="loading === true || results"
                    >
                        <span :class="{ 'is-loading': loading && !results, 'is-idle': !loading && results }">
                            {{ !loading && !results ? 'Lancer' : (loading && !results ? 'Chargement...' : (!loading && results ? 'Chargé': '')) }}
                        </span>
                    </v-btn>

                    <v-btn
                        v-if="!loading && results"
                        round
                        style="background-color: black; color:white; margin:0 auto;"
                        @click="clearResults()"
                    >
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>

        <!--  Box pour résultat  -->
        <div class="sandBox-box-results">
            <h2 class="title-section-wrapper">
                <span>Console</span>
            </h2>

            <!-- Injection des résultats -->
            <div class="results-content">
                <TreeItem v-if="results" :value="results" />
                <div v-else-if="loading && !results">Requête en cours...<br>Merci de patienter!</div>
                <div v-else-if="!loading && !results">La console n'est pas encore chargé!<br> Utilisez une route connu du système avec la méthode approprié et lancez la requête.</div>
            </div>
        </div>
    </div>
</template>

<script>
import TreeItem from "@/components/dev/controls/TreeItem.vue";

export default {
    name: "DevApi",
    props: {
        // Méthode centralisée d'appel API passée par le parent
        makeApiRequest: {
            type: Function,
            required: true
        }
    },
    components: {
        TreeItem
    },
    data: function() {
        return {
            selectedActionType: 'get',
            selectedUri:'',
            actionsTypes: [
                { text: 'GET', value: 'get' },
                { text: 'POST', value: 'post' },
                { text: 'PUT', value: 'put' },
                { text: 'DELETE', value: 'delete' },
            ],
            results: null,
            loading: false,
        }
    },
    methods:{
        // Appel API via la méthode centrale du parent
        async fetchTypeAction(methodName, uri) {
            this.loading = true;
            try {
                this.results = await this.makeApiRequest(uri, methodName);
                this.loading = false;
            } catch (error) {
                this.results = error;
                this.loading = false;
            }
        },
        clearResults(){
            this.results = null;
            this.selectedUri = '';
        }
    }
}
</script>

<style scoped>
.container-wrapper{
    display:inline-flex;
    width:100%;
    overflow-x:auto;
    overflow-y: hidden;
}
.sandBox-box-query{
    width:35%;
    height: 270px;
    background-image: linear-gradient(to bottom, #969696, #6c6c6c);
    margin:0 auto;
    border:2px outset silver;
    border-radius: 8px;
    color:black;
    box-shadow: 1px 1px 5px rgba(1, 1, 1, 0.3);
}
.sandBox-box-results{
    width:64%;
    height: 550px;
    background-color: #252424;
    margin:0 auto;
    border:2px outset silver;
    border-radius: 8px;
    color:black;
    box-shadow: 1px 1px 5px rgba(1, 1, 1, 0.3);
}
.title-section-wrapper {
    background-color: purple;
    color: white;
    border-top: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0.5rem 1rem;
}
.results-content {
    color: chartreuse;
    height: 100%;
    overflow-y: auto;
    padding: 0.5rem 1rem;
}
.is-loading {
    color: red;
}
.is-idle {
    color: chartreuse;
}
</style>

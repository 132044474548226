<template>
    <v-container fluid
    >
        <div class="viewTitle mb-2 pa-2">Développement</div>
        <v-tabs v-model="active" color="purple" dark slider-color="white">
            <div class="pt-2" id="wrapper-tab">
                <v-tab v-for="tab in tabs" :key="tab.id" ripple>
                    <v-icon>{{tab.icon}}</v-icon>
                </v-tab>
            </div>
            <v-tab class="pt-1"><v-icon @click="startTour()">info</v-icon></v-tab>
            <v-tab-item v-for="tab in tabs" :key="tab.id">
                <v-card flat>
                    <v-container fluid class="tab-box-wrapper">
                        <h2 class="mt-0 ml-2 mb-3">{{ currentComponentName }}</h2>
                        <div class="pa-0" id="wrapper-section">
                            <component
                                v-bind:is="currentComponent"
                                @showToastMessage="showToastMessage"
                                :saveCodeToDatabase="saveCodeToDatabase"
                                :makeApiRequest="makeApiRequest"
                            />
                        </div>
                    </v-container>
                </v-card>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
import $axios from '../api';
import DevSandBox from "@/components/dev/DevSandBox.vue";
import DevApi from "@/components/dev/DevApi.vue";
import DevTemplate from "@/components/dev/DevTemplate.vue";
import DevDoc from "@/components/dev/DevDoc.vue";
import DevSetting from "@/components/dev/DevSetting.vue";
import { driver } from "driver.js";

export default {
    name: "Developpement",
    components: {
        DevSandBox,
        DevApi,
        DevTemplate,
        DevDoc,
        DevSetting,
    },
    data(){
        return {
            active: null,
            tabs: [
                {
                    id: 0,
                    name: 'Contrôle Api',
                    icon: 'api',
                    component: 'DevApi'
                },
                {
                    id: 1,
                    name: 'Code Templates',
                    icon: 'dashboard',
                    component: 'DevTemplate'
                },
                {
                    id: 2,
                    name: 'Documentations',
                    icon: 'article',
                    component: 'DevDoc'
                },
                {
                    id: 3,
                    name: 'SandBox',
                    icon: 'code',
                    component: 'DevSandBox'
                },
                {
                    id: 4,
                    name: 'Paramètres',
                    icon: 'settings',
                    component: 'DevSetting'
                },
            ]
        }
    },
    computed: {
        // ...mapState({
        //     entrepriseStore: state => state.entrepriseStore,
        // }),

        currentComponent(){
            if(this.active){
                return  this.tabs[this.active].component;
            } else {
                return this.tabs[0].component;
            }

        },
        currentComponentName(){
            if(this.active){
                return  this.tabs[this.active].name;
            } else {
                return this.tabs[0].name;
            }

        }
    },
    methods: {
        // Méthode centralisée pour Toast message
        showToastMessage(eventObject) {
            switch (eventObject.type) {
                case "success":this.$toast.success(eventObject.message || "Success! Opération réussie !");
                break;
                case "error":this.$toast.error(eventObject.message || "Erreur! Une erreur est survenue !");
                break;
                case "info":this.$toast.info(eventObject.message || "Info! Voici une information importante !");
                break;
                case "warning":this.$toast.warning(eventObject.message || "Avertissement ! Faites attention !");
                break;
                default:this.$toast(eventObject.message || "Voici un message !");
                break;
            }
        },
        // Méthode centralisée pour appels API
        async makeApiRequest(endpoint, method = "GET", data = null) {
            try {
                const response = await $axios({
                    method,
                    url: `${endpoint}`,
                    data
                });
                this.showToastMessage({ type: "success", message: "Requête API réussie !" });
                return response.data;
            } catch (error) {
                this.showToastMessage({ type: "error", message: error.response?.data?.message || "Erreur lors de l'appel API" });
                console.error("[API Error]:", error);
                throw error;
            }
        },
        // Method pour sauvegarder un code dans la base de données
        async saveCodeToDatabase(payload) {
            try {
                const response = await this.makeApiRequest("saveCode", "POST", payload);
                console.log("[Database Save]:", response);
                this.showToastMessage({ type: "success", message: "Code sauvegardé avec succès !" });
            } catch (error) {
                // Les erreurs seront automatiquement gérées dans `makeApiRequest`
            }
        },
        startTour(){
            this.$nextTick(() => {
                const driverObj = driver({
                    nextBtnText: 'Suivant',
                    prevBtnText: 'Retour',
                    doneBtnText: 'Fin',
                    overlayColor: 'gray',
                    showProgress: false,
                    steps: [
                        {  element: '#wrapper-section',
                            popover: {
                                title: 'Bienvenue sur le DevCoreToolBox!',
                                description: 'La bible des développeurs Stageline. Ici vous pourrez utiliser les outils qui composent le DevCoreToolBox et ainsi maximiser votre travail.',
                                side: "top",
                                align: 'center'
                            }
                        },
                        {  element: '#wrapper-tab',
                            popover: {
                                title: 'Les outils de la boîte',
                                description: 'Vous pouvez changer d\'outils ici!',
                                side: "bottom",
                                align: 'start'
                            }
                        },
                    ]
                });
                driverObj.drive();
            });
        },
    }
}
</script>

<style scoped>
.tab-box-wrapper{
    min-height:700px;
    background-image: linear-gradient(to bottom, #181818, #262424);
    color:white;
    padding:16px 12px;
}
.driver-popover.driverjs-theme-section {
    background-color: #ffcc80 !important;
    border: 2px solid #ff9800 !important;
}

</style>

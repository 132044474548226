<template>
    <div class="container-wrapper">
        <!-- Dialogue pour éditer la note -->
        <v-dialog v-model="showNoteDialog" max-width="500px">
            <v-card class="pb-2" color="purple">
                <v-card-title>
                    <h2 style="color:white;">Note du model</h2>
                </v-card-title>
                <v-card-text>
                    <v-container fluid grid-list-md dark ma-0 pa-0 style="background-color: white;">
                        <v-textarea
                            :value="testNoteString"
                            color="purple"
                            box
                            auto-grow
                            hide-details
                        ></v-textarea>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn fab dark color="success" @click="">
                        <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn fab dark color="error" @click="showNoteDialog = false">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialogue pour créer/éditer le contenu -->
        <v-dialog v-model="showEditDialog" max-width="850px">
            <v-card class="pb-2" color="purple">
                <v-card-title>
                    <h2 style="color:white;">{{ isCreating ? 'Créer un nouvel élément' : 'Modifier le code' }}</h2>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div v-if="isCreating">
                            <!-- Stepper pour mode création -->
                            <v-stepper
                                v-model="currentStep"
                                dark
                                class="stepper-wrapper"
                            >
                                <!-- Étapes du stepper -->
                                <v-stepper-header>
                                    <v-stepper-step :complete="currentStep > 1" step="1">Saisie du code</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step :complete="currentStep > 2" step="2">Ajout des informations</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3">Aperçu et finalisation</v-stepper-step>
                                </v-stepper-header>

                                <!-- Contenu du stepper -->
                                <v-stepper-items>
                                    <!-- Étape 1 -->
                                    <v-stepper-content step="1">
                                        <h3 style="color:white">Étape 1 : Saisissez le code</h3>
                                        <pre
                                            style="overflow-y: auto; height: 380px;"
                                            contenteditable="true"
                                            class="code-editor"
                                            @input="onCodeInput"
                                            @paste="onPaste"
                                            ref="editableCodeContainer"
                                        >{{ editableCode }}</pre>
                                        <div style="width: 100%; text-align: center;">
                                            <v-btn color="primary" class="mt-4" @click="currentStep = 2">Suivant</v-btn>
                                            <v-btn color="error" class="mt-4" @click="showEditDialog = false">cancel</v-btn>
                                        </div>
                                    </v-stepper-content>
                                    <!-- Étape 2 -->
                                    <v-stepper-content step="2">
                                        <h3 style="color:white">Étape 2 : Ajoutez des informations</h3>
                                        <div style="overflow-y: auto; height: 380px;">
                                            <!-- Sélection du nom -->
                                            <v-text-field
                                                label="Titre"
                                                v-model="newEntryName"
                                                outlined
                                            ></v-text-field>
                                            <!-- Sélection de la description -->
                                            <v-textarea
                                                label="Description"
                                                v-model="newEntryDescription"
                                                outlined
                                            ></v-textarea>
                                            <!-- Sélection du modèle -->
                                            <v-select
                                                label="Type de code"
                                                v-model="newEntryModel"
                                                :items="typeCodeList.map(type => type.name)"
                                                item-value="name"
                                                item-text="name"
                                                placeholder="Choisir un type ..."
                                            ></v-select>
                                            <!-- Sélection de la version (dépend du modèle choisi) -->
                                            <v-select
                                                label="Version"
                                                v-model="newEntryVersion"
                                                :items="selectedTypeVersions"
                                                placeholder="Choisir une version ..."
                                                :disabled="!newEntryModel"
                                            ></v-select>
                                            <!-- Saisie du champ "Scope" -->
                                            <v-select
                                                label="Scope"
                                                v-model="newEntryScope"
                                                :items="scopeOptions"
                                                placeholder="Choisir un scope ..."
                                            ></v-select>
                                            <!-- Sélection des applications -->
                                            <v-select
                                                label="Application"
                                                v-model="newEntryApp"
                                                :items="stagelineAppList.map(app => app.app)"
                                                placeholder="Sélectionner une application ..."
                                            ></v-select>
                                        </div>
                                        <div style="width: 100%; text-align: center;">
                                            <v-btn color="primary" class="mt-4" @click="currentStep = 3">Suivant</v-btn>
                                            <v-btn color="grey" class="mt-4" @click="currentStep = 1">Retour</v-btn>
                                            <v-btn color="error" class="mt-4" @click="showEditDialog = false">cancel</v-btn>
                                        </div>
                                    </v-stepper-content>
                                    <!-- Étape 3 -->
                                    <v-stepper-content style="color:white;" step="3">
                                        <h3 >Étape 3 : Vérifiez vos données</h3>
                                        <div style="overflow-y: auto; height: 380px;">
                                            <div><strong>Nom : </strong><span class="stepper-txt-confirm">{{ newEntryName }}</span></div>
                                            <div><strong>Description : </strong><span class="stepper-txt-confirm">{{ newEntryDescription }}</span></div>
                                            <div><strong>Type de code : </strong><span class="stepper-txt-confirm">{{ newEntryModel }}</span></div>
                                            <div><strong>Version : </strong><span class="stepper-txt-confirm">{{ newEntryVersion }}</span></div>
                                            <div><strong>Scope : </strong><span class="stepper-txt-confirm">{{ newEntryScope }}</span></div>
                                            <div><strong>Application : </strong><span class="stepper-txt-confirm">{{ newEntryApp }}</span></div>
                                            <pre><strong>Code : </strong><br><span class="stepper-txt-confirm">{{ editableCode }}</span></pre>
                                        </div>
                                        <div style="width: 100%; text-align: center;">
                                            <v-btn color="green" class="mt-4" @click="saveNewEntry">Créer</v-btn>
                                            <v-btn color="grey" class="mt-4" @click="currentStep = 2">Retour</v-btn>
                                            <v-btn color="error" class="mt-4" @click="showEditDialog = false">cancel</v-btn>
                                        </div>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                        <!-- Section par défaut pour mode édition -->
                        <div v-else>
                            <pre
                                contenteditable="true"
                                class="code-editor"
                                @input="onCodeInput"
                                ref="editableCodeContainer"
                            >{{ editableCode }}</pre>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!isCreating">
                    <v-spacer></v-spacer>
                    <v-btn fab dark color="success" @click="saveEditedCode()">
                        <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn fab dark color="error" @click="showEditDialog = false">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="wrapper-box-left">
            <h2 class="title-section-wrapper">
                <span>Visionneur</span>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="currentCodeShow" v-on="on" color="white" style="float:right;margin-left: 5px;" @click="openTemplateNote">speaker_notes</v-icon>
                    </template>
                    <span>Ouvrir la note du model</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="currentCodeShow" v-on="on" color="white" style="float:right;margin-left: 5px;" @click="copyToClipboard">content_copy</v-icon>
                    </template>
                    <span>Presse-papier</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-if="currentCodeShow"
                            v-on="on"
                            color="white"
                            style="float:right; margin-left: 5px;"
                            @click="openEditDialog"
                        >
                            edit
                        </v-icon>
                    </template>
                    <span>Modifier le code</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            color="white"
                            style="float:right;"
                            @click="openCreateDialog"
                        >
                            add
                        </v-icon>
                    </template>
                    <span>Créer un nouvel élément</span>
                </v-tooltip>
            </h2>

            <div v-if="currentCodeShow">
                <pre><code class="code-wrapper language-js" v-html="escapedCode"></code></pre>
            </div>
            <div v-else class="icon-center">
                <v-icon color="white" x-large>image_not_supported</v-icon>
                <span class="overlay-text">Aucun model n'a encore été sélectionné!</span>
            </div>
        </div>
        <div class="wrapper-box-right">
            <h2 class="title-section-wrapper">
                <span>Model</span>
            </h2>

            <v-card style="background-color: #9a9a9a;" elevation="0">
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Rechercher"
                        single-line
                        hide-details
                        color="purple"
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="codeLangages"
                    :search="search"
                    hide-actions
                    style=""
                >
                    <template v-slot:headers="props">
                        <th v-for="(header, index) in props.headers" style="text-align: left; background-color: purple; color:white;border:1px solid purple;font-size: 15px;">
                            {{header.text}}
                        </th>
                    </template>
                    <template v-slot:items="props">
                        <tr class="model-row" :class="{ selected: selectedItem === props.item }" @click="showTemplate(props.item)">
                            <td>{{ props.item.name }}</td>
                            <td>{{ props.item.description }}</td>
                            <td>{{ props.item.type }}</td>
                            <td>{{ props.item.version }}</td>
                            <td>{{ props.item.model }}</td>
                            <td>{{ props.item.scope }}</td>
                            <td>{{ props.item.app }}</td>
                        </tr>
                    </template>
                    <template v-slot:no-results>
                        <v-alert :value="true" color="error" icon="warning">
                            Aucun résultat pour "{{ search }}"
                        </v-alert>
                    </template>
                </v-data-table>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "DevTemplate",
    props: {
        saveCodeToDatabase: {
            type: Function,
            required: true
        }
    },
    components: {
    },
    mounted() {
        this.highlightCode(); // Coloration quand le composant est monté
    },
    data: function() {
        return {
            showNoteDialog:false,
            showEditDialog: false,
            isCreating:false,
            loading: null,
            search: '',
            headers: [
                { text: 'Titre', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Type', value: 'type' },
                { text: 'Version', value: 'version' },
                { text: 'Model', value: 'model' },
                { text: 'Porté', value: 'scope' },
                { text: 'App', value: 'app' },
            ],
            codeLangages: [
                {
                    name: 'Page parent d\'une section',
                    description: 'Page parent indexé au routeur de l\'application',
                    type: 'Vue',
                    version: '2.7.16',
                    model: 'Main view',
                    app: 'GNC',
                    scope: 'Frontend',
                },
                {
                    name: 'Page parent d\'une section',
                    description: 'Page parent indexé au routeur de l\'application',
                    type: 'AngularJs',
                    version: '1.3.0',
                    model: 'Main view',
                    app: 'Smart',
                    scope: 'Frontend',
                }
            ],
            currentCodeShow:null,
            selectedItem: null,
            currentStep: 1,
            newEntryName: '',
            newEntryDescription: '',
            newEntryType: '',
            newEntryVersion: '',
            newEntryModel: '',
            newEntryScope: '',
            newEntryApp: '',

            typeCodeList: [
                {
                    name: "JavaScript",
                    versions: ["ES5", "ES6", "ES7", "ES8", "ES2020", "ES2021"],
                },
                {
                    name: "Python",
                    versions: ["2.7", "3.6", "3.7", "3.8", "3.9", "3.10"],
                },
                {
                    name: "Java",
                    versions: ["8", "11", "15", "17", "20"],
                },
                {
                    name: "C#",
                    versions: ["4.0", "5.0", "6.0", "7.0", "8.0", "9.0", "10.0"],
                },
                {
                    name: "PHP",
                    versions: ["5.6", "7.0", "7.4", "8.0", "8.1", "8.2"],
                },
                {
                    name: "C++",
                    versions: ["98", "03", "11", "14", "17", "20", "23"],
                },
                {
                    name: "TypeScript",
                    versions: ["3.2", "3.9", "4.0", "4.4", "4.8", "5.0"],
                },
                {
                    name: "Ruby",
                    versions: ["1.9", "2.0", "2.5", "2.6", "3.0"],
                },
                {
                    name: "Go",
                    versions: ["1.12", "1.15", "1.17", "1.18", "1.21"],
                },
                {
                    name: "Rust",
                    versions: ["1.0", "1.30", "1.50", "1.60", "1.70"],
                },
                {
                    name: "AngularJS",
                    versions: ["1.3", "1.5", "1.6", "1.7"],
                },
                {
                    name: "Vuetify",
                    versions: ["1.5", "2.0", "2.1", "2.2", "2.3", "2.6"],
                },
                {
                    name: "Nuxt",
                    versions: ["1.0", "2.0", "2.15", "3.0", "3.5"],
                },
                {
                    name: "Vue.js",
                    versions: ["2.0", "2.6", "3.0", "3.2", "3.3"],
                },
            ],
            stagelineAppList:[
                { app:'GNC' },
                { app:'Gallup' },
                { app:'Hub' },
                { app:'Smart' },
                { app:'SAV' },
                { app:'Smart_Power' },
                { app:'Autre' },
            ],
            scopeOptions: ["Frontend", "Backend", "Configuration", "Autre"],

            //TEST *KB*
            editableCode: "",
            testNoteString:"Je suis une note test!",
            testStringCode: "&lt;template&gt;\n" +
                "&lt;/template&gt;\n" +
                "\n" +
                "&lt;script&gt;\n" +
                "export default {\n" +
                "    name: &quot;NomDuParent&quot;,\n" +
                "    components: {\n" +
                "    },\n" +
                "    data: function() {\n" +
                "        return {\n" +
                "            loading: null,\n" +
                "        }\n" +
                "    },\n" +
                "    methods:{\n" +
                "    }\n" +
                "}\n" +
                "&lt;/script&gt;",
        }
    },
    computed: {
        // Code décodé et retransformé pour afficher les balises comme texte
        escapedCode() {
            const decoded = this.testStringCode
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, '"')
                .replace(/&nbsp;/g, " ")
                .replace(/&amp;/g, "&");

            // Encode les balises à nouveau pour empêcher leur interprétation
            return decoded
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;");
        },
        selectedTypeVersions() {
            // Trouve les versions associées au modèle choisi
            const selectedType = this.typeCodeList.find(
                (type) => type.name === this.newEntryModel
            );
            return selectedType ? selectedType.versions : [];
        },
    },
    methods:{
        onCodeInput(event) {
            const inputText = event.target.innerText;
            if (this.editableCode !== inputText) {
                this.editableCode = inputText; // Ne modifiez que si nécessaire
            }
        },
        onPaste(event) {
            event.preventDefault();
            this.editableCode = event.clipboardData.getData('text/plain');
        },
        openTemplateNote(){
            this.showNoteDialog = true;
        },
        showTemplate(item){
            this.selectedItem = item;

            //*NOTE* testStringCode sera remplacé par les données reçu via API *KB*
            this.testStringCode = this.decodeHTMLCode(this.testStringCode);

            this.currentCodeShow = this.testStringCode;
        },
        decodeHTMLCode(htmlCode) {
            // Remplace les entités HTML par les caractères réels
            return htmlCode
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, '"')
                .replace(/&nbsp;/g, " ")
                .replace(/&amp;/g, "&");
        },
        copyToClipboard() {
            let htmlCode = this.decodeHTMLCode(this.testStringCode);
            navigator.clipboard.writeText(htmlCode).then(() => {
                alert('Copying to clipboard was successful!');
            }, (err) => {
                alert('Could not copy text: '+ err);
            });
        },
        highlightCode() {
            this.$nextTick(() => {
                if (window.hljs) {
                    window.hljs.highlightAll();
                } else {
                    console.error("Highlight.js introuvable.");
                }
            });
        },
        openEditDialog() {
            this.isCreating = false;
            this.editableCode = this.testStringCode;
            this.showEditDialog = true;
        },
        openCreateDialog() {
            this.isCreating = true;
            this.editableCode = '';
            this.newEntryName = '';
            this.newEntryDescription = '';
            this.currentStep = 1;
            this.showEditDialog = true;
        },
        async saveEditedCode() {
            try {
                // Sauvegarde via la méthode du parent
                await this.saveCodeToDatabase({ code: this.editableCode });
                // Mettre à jour le code visible avec le contenu sauvegardé
                this.escapedCode = this.editableCode;
                // Fermer le dialogue
                this.showEditDialog = false;
            } catch (error) {
                console.error("Erreur lors de la sauvegarde :", error);
            }
        },
        saveNewEntry() {
            //TODO : Logique pour sauvegarder la nouvelle entrée
            console.log('Nouvelle entrée créée:', this.editableCode);
            this.showEditDialog = false;
        },
    },
    watch: {
        testStringCode() {
            this.highlightCode();
        },
    },
}
</script>

<style scoped>
.container-wrapper{
    display:inline-flex;
    width:100%;
    overflow-x:auto;
    overflow-y: hidden;
}
.wrapper-box-left{
    width:40%;
    height: 550px;
    margin:0 auto;
    border:2px outset silver;
    border-radius: 8px;
    background-color: #282c34;
    box-shadow: 1px 1px 5px rgba(1, 1, 1, 0.3);
}
.wrapper-box-right{
    width:59%;
    height: 550px;
    margin:0 auto;
    border:2px outset silver;
    border-radius: 8px;
    background-color:#9a9a9a;
    box-shadow: 1px 1px 5px rgba(1, 1, 1, 0.3);
}
.title-section-wrapper {
    background-color: purple;
    color: white;
    border-top: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0.5rem 1rem;
}
.icon-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: -40px; /* adjust this value as per your requirements */
}
.overlay-text {
    color: white;
}
.code-wrapper{
    height: 500px;
    font-size: 16px;
    overflow-y: auto;
}
.model-row{
    background-color: #9a9a9a;
    border:1px solid #9a9a9a;
    cursor:pointer;
}
.model-row td{
    font-size: 14px;
    font-weight: bolder;
    font-family: "Source Code Pro", monospace;
    border-bottom: 1px solid purple;
}
.selected {
    background-color: white;
}
.code-editor {
    font-family: "Source Code Pro", monospace;
    background: #282c34;
    color: #ffffff;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    overflow: auto;
    height: 300px;
}
.stepper-wrapper{
    height: 590px;
}
.stepper-txt-confirm{
    color: chartreuse;
}
</style>

import $axios from '../../api';

const state = {
    token: localStorage.getItem('access_token') || null,
    loggedUser: {},
    navItems: [],
};

const mutations = {
    updateTokenInfo(state, infos){
        localStorage.setItem('access_token', infos.access_token);
        state.token = infos.access_token;
    },

    updateLoginInfos(state, infos){
        state.loggedUser = infos.user;
        state.navItems = [
            { title: 'Tableau de bord', icon: 'dashboard', link: { name: 'dashboard'} },
            { title: 'Créer', icon: 'create_new_folder', link: { name: 'create'}},
            { title: 'Notifications', icon: 'notifications', link: { name: 'notifications'}},
        ];

        if (!!state.loggedUser.groups.find(g => { return g.name === 'gnc_admins'})){

            //Page des rapports
            state.navItems.push(
                {
                     title: 'Rapports', icon: 'pie_chart', link: { name: 'rapports'}
                }
            );

            //Page Adminsitration
            state.navItems.push(
                {
                    title: 'Administration', icon: 'settings', link: { name: 'administration'}
                }
            );
            if (!!state.loggedUser.groups.find(g => { return g.name === 'admin'})){
                //Page Dev
                state.navItems.push(
                    {
                        title: 'Développement', icon: 'build_circle', link: { name: 'developpement'}
                    }
                );
            }
        }
    },

    loggedOut: (state) => {
        state.loggedUser = {};
        state.token = '';
        localStorage.setItem('access_token', '');
        localStorage.setItem('lastPage', '');
    },

    updateUserSetting(state, setting) {
        state.loggedUser.userSettings = state.loggedUser.userSettings.map(userSetting => {
            if (setting.id === userSetting.id) {
                userSetting.value = setting.value;
            }
            return userSetting;
        });
    }
};

const actions = {
    validateLogin({ commit, dispatch }, credentials){

        return new Promise( (resolve, reject) => {
            $axios.post(`/auth/login`, {
                email: credentials.email,
                password: credentials.password,
                remember: credentials.remember
            })
            .then(response => {
                commit('updateTokenInfo',response.data);
                dispatch('getUser').then( rep => {
                    let infos = {
                        access_token: response.data.access_token,
                        user: rep.data.user
                    };
                    commit('updateLoginInfos', infos);
                    resolve(true);
                });
            })
            .catch( error => {
                if (error.response.data.errors && error.response.data.errors.message) {
                    reject({message: error.response.data.errors.message})
                }
                else {
                    reject(error);
                }
            });
        });
    },

    getUser({ commit }){
        return new Promise( (resolve, reject) => {
            $axios.get(`/auth/user`).then(response => {
                resolve(response);
            })
            .catch( error => {
                reject(error);
            });
        });
    },

    loggedOut( { commit } ) {
        commit('loggedOut')
    },

    async updateUserSetting({state, commit}, userSetting) {
        let userId = state.loggedUser.id;
        let settingId = userSetting.setting.id;
        const settings = await $axios.put(`/settings/user/${userId}/${settingId}`, userSetting);
        commit('updateUserSetting', settings.data);
    }
};


const getters = {
    loggedUser: (state) => {
        return state.loggedUser
    },

    loggedUserInitials: (state) => {
        if(Object.keys(state.loggedUser).length > 0){
            return (state.loggedUser.firstName.charAt(0) + state.loggedUser.lastName.charAt(0)).toUpperCase();
        } else {
            return "";
        }
    },

    loggedIn: (state) => {
        return Object.keys(state.loggedUser).length > 0
    },

    isGncAdmin: (state) =>  {
        if (Object.keys(state.loggedUser).length > 0) {
            return !!state.loggedUser.groups.find(g => { return g.name === 'gnc_admins'});
        } else {
            return false;
        }
    },

    isGncQualite: (state) =>  {
        if (Object.keys(state.loggedUser).length > 0) {
            return !!state.loggedUser.groups.find(g => { return g.name === 'gnc_qualite'});
        } else {
            return false;
        }
    },

    isGncSupervisor: (state) =>  {
        if (Object.keys(state.loggedUser).length > 0) {
            return !!state.loggedUser.groups.find(g => { return g.name === 'gnc_superviseurs'});
        } else {
            return false;
        }
    },

    isGncUser: (state) =>  {
        if (Object.keys(state.loggedUser).length > 0) {
            return !!state.loggedUser.groups.find(g => { return g.name === 'gnc_usagers'});
        } else {
            return false;
        }
    },

    isGNCMaintenance: (state) => {
        let isMaintenance = false;
        if (state.loggedUser && state.loggedUser.groups != null) {

            isMaintenance = (state.loggedUser.groups.filter(group => group.name.includes('maintenance')).length > 0);
        }
        return isMaintenance;
    },

    isGNCSAV: state => {
        let isMaintenance = false;

        //if there is a user and the user belongs to at least a group
        if (state.loggedUser && state.loggedUser.groups != null) {

            //looking at all groups the user belongs to
            //returning an array with all users groups that contain sav in their name
            isMaintenance = (state.loggedUser.groups.filter(group => group.name.includes('sav')).length > 0);
        }
        return isMaintenance;
    },

    getNavItems: (state) => {
        return state.navItems
    },

    getAccessToken: (state) => {
        return state.token;
    },
};

export default {
  state, actions, mutations, getters
}

<template>
    <div class="container-wrapper">
        <div class="icon-center">
            <h2 class="overlay-text">" Cette section est actuellement en développement! "</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: "DevSandBox",
    components: {
    },
    data: function() {
        return {
            loading: null,
        }
    },
    methods:{
    }
}
</script>

<style scoped>
.container-wrapper{
    display:inline-flex;
    width:100%;
    overflow-x:auto;
    overflow-y: hidden;
}

/*Temporaire*/
.icon-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 550px;
    margin:0 auto;
}
.overlay-text {
    color: white;
}
</style>
import { render, staticRenderFns } from "./DevTemplate.vue?vue&type=template&id=4c1d16e6&scoped=true"
import script from "./DevTemplate.vue?vue&type=script&lang=js"
export * from "./DevTemplate.vue?vue&type=script&lang=js"
import style0 from "./DevTemplate.vue?vue&type=style&index=0&id=4c1d16e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1d16e6",
  null
  
)

export default component.exports
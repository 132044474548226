<template>
  <v-app :class="classComponent">
    <Navbar v-show="!isHomePage" class="print-none"/>

    <v-content>
        <router-view :key="$route.path"/>
    </v-content>

      <!-- Loading dialog -->
      <v-dialog
          v-model="entrepriseStore.showLoading"
          max-width="400"
          content-class="no-shadow"
      >
          <v-card
              color="rgba(0,0,0,0)"
              height="400"
          >
              <v-card-text
                  class="text-xs-center"
              >
                  <p class="display-2" style="color:white">Chargement</p>
                  <v-progress-circular
                      indeterminate
                      color="white"
                      size="120"
                  ></v-progress-circular>
              </v-card-text>
          </v-card>
      </v-dialog>
  </v-app>
</template>

<script>

import Navbar from "./components/controls/Navbar";
import { LOGIN_STORE, ENTREPRISE_STORE } from "./constants";
import $axios from './api';
import '../styles/index.scss';
import {mapGetters, mapState} from 'vuex';

export default {
    name: 'App',
    components: { Navbar },
    created() {
        if(this.loggedIn){
            this.$store.dispatch(ENTREPRISE_STORE.actions.FETCH_USERS).then( response => {});
        }
    },
    computed: {
        ...mapState({
            entrepriseStore: state => state.entrepriseStore,
        }),

        ...mapGetters({
            loggedIn: LOGIN_STORE.getters.LOGGED_IN,
        }),

        classComponent(){
            return this.$route.name === 'home' ? 'login-background' : "";
        },
        isHomePage(){
            return this.$route.path === '/';
        }
    },
    watch: {
        '$route'() {
            this.$nextTick(() => {
                if (typeof window.hljs !== 'undefined') {
                    window.hljs.highlightAll(); // Réapplique Highlight.js sur les blocs.
                }
            });
        },
    }
}
</script>

<style scoped>
    .login-background {
        background-image: url('./assets/login-background.png');
        background-size: cover;
    }

    /* print css */
    @media print {
        .print-none {
            display: none;
        }
    }

    .no-shadow {
        box-shadow:none !important;
    }
</style>

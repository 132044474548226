<template>
    <div>
        <div v-if="isObject(value)" style="margin-left: 10px;">
            <div
                v-for="(item, key) in value"
                :key="key"
            >
                <span class="key" @click="open(item)" style="cursor:pointer;">{{ key }} > </span>
                <Tree :value="item" v-show="item.isOpen"/>
            </div>
        </div>
        <pre v-else>{{value}}</pre>
    </div>
</template>

<script>
export default {
    name: 'Tree',
    props: {
        value: {required: true},
    },
    methods: {
        isObject: function (item) {
            return (typeof item === "object" && !Array.isArray(item) && item !== null);
        },
        open: function (item) {
            this.$set(item, 'isOpen', !item.isOpen);
        },
    }
}
</script>